<template>
<!--  :style="{-->
<!--  margin: props.list.length >= 5 ? '0 auto' : '',-->
<!--  }"-->
  <client-only>
    <div
        v-masonry
        :gutter="20"
        :infinite-scroll-immediate="true"
        class="cardBox"
        item-selector=".card"
        origin-left="true"
        transition-duration="0s"
        destroy-delay="0"
    >
      <div
          v-for="(opus, index) in props.list"
          :key="opus.id"
          v-masonry-tile
          class="card"
      >
        <opusBox :card-data="opus" :userIdentificationSize="10" @click.stop="toImgTextDetails(opus)"></opusBox>
      </div>
    </div>
  </client-only>
</template>

<script setup lang="ts">
import opusBox from './opusCard.vue'
const router = useRouter()
const props:any = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
})
enum PageType {
  '/videoDetail' = 5,     //视频
  '/imgTextDetail' = 6,   //图文
  '/caseDetail' = 9       //整屋案例
}
// 查看作品详情
const toImgTextDetails = (opus) => {
  const routerUrl = router.resolve({
    path: PageType[opus.platformCaseType],
    query: {
      id: opus.id
    }
  })
  window.open(routerUrl.href, "_blank")
}
</script>
<style lang="less" scoped>
.cardBox{
  padding-bottom: 20px;
  padding-top: 20px;
  .card{
     width:363px;
    //width: calc((100% - 60px) / 4);
    padding-bottom: 20px;
    //margin-bottom: 20px;
  }
}

@media (max-width: 1450px) {
  .card{
    //width: calc((100% - 40px) / 3)
  }
}
</style>